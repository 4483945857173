import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../contexts/spinner.context";
import * as MemberInfoAllApi from "../services/MemberAPI";
import { serverConstants } from "../utils/constants";
import { parseRouteUrl, getUserType } from "../utils/helpers";
import {
  SUBSCRIBER,
  HEALTH_PLAN_RENEWALS,
  MARKETPLACE,
} from "../define.constants";
import { ErrorContext } from "../contexts/error.context";
import { brightCarePortal } from "../utils/helpers";

const useMemberAPI = () => {
  const { setError } = useContext(ErrorContext);
  const { setSpinner } = useContext(SpinnerContext);
  const [userType, setUserType] = useState(SUBSCRIBER);
  const [unreadInboxMsgCount, setUnreadInboxMsgCount] = useState(0);
  const [refreshSession, setRefreshSession] = useState(false);
  const [refreshSessionAndReload, setRefreshSessionAndReload] = useState(false);
  const [isMemberAPICalled, setIsMemberAPICalled] = useState(false);
  const [memberFirstName, setMemberFirstName] = useState("");
  const [memberLastName, setMemberLastName] = useState("");
  //should get State details of member use MemberStateName instead of statecode
  const [memberStateCode, setMemberStateCode] = useState("");
  const [memberLOB, setMemberLOB] = useState("");
  const [id, setId] = useState("");
  const [graceStatus, setGraceStatus] = useState("");
  const [memberRKSID, setMemberRKSID] = useState("");
  const [programId, setProgramId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [userId, setUserId] = useState("");
  const [zip, setZip] = useState("");
  //added from meminfoall
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [planName, setPlanName] = useState("");
  const [coverageEffDt, setCoverageEffDt] = useState("");
  const [planTerminationDt, setPlanTerminationDt] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");
  const [prefEmailId, setPrefEmailId] = useState("");
  const [prefPhone, setPrefPhone] = useState("");
  const [prefLang, setPrefLang] = useState("");
  const [memberId, setMemberId] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [memberName, setMemberName] = useState("");
  const [MemberStateName, setMemberStateName] = useState("");
  const [PPHRAlerts, setPPHRAlerts] = useState([]);
  const [showAccordant, setShowAccordant] = useState(false);
  const [planId, setPlanId] = useState("");
  const [DependentCategory, setDependentCategory] = useState("");
  const [isAIANMember, setIsAIANMember] = useState(false);
  const [EnrollmentStatus, setEnrollmentStatus] = useState("");
  const [primaryCaseManager, setPrimaryCaseManager] = useState("");
  const [enrollmentEffDate, setEnrollmentEffDate] = useState("");
  // adding for payers
  const [DependentDetails, setDependentDetails] = useState("");
  //is use to check if the api data is lodaded completely
  const [IsDualEligible, setIsDualEligible1] = useState("");
  const [isMemInfoResponseLoaded, setIsMemInfoResponseLoaded] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(0);
  const [isTermedMember, setIsTermedMember] = useState(false);
  const [isSupportUser, setIsSupportUser] = useState(false);
  const [supportUserId, setSupportUserId] = useState("");
  const [isNYAffinityMember, setIsNYAffinityMember] = useState("");
  const [isILHBIMember, setIsILHBIMember] = useState("");
  const [isFIMCMember, setIsFIMCMember] = useState("");
  const [isDualEligible, setIsDualEligible] = useState("");
  const [IsWISSIMember, setIsWISSIMember] = useState("");
  const [isNYEssentialPlanUser, setIsNYEssentialPlanUser] = useState("");
  const [IsMCCMemberFlag, setIsMCCMemberFlag] = useState("");
  const [RedeterminationAlerts, setRedeterminationAlerts] = useState([]);
  const [RedeterminationDate, setRedeterminationDate] = useState("");
  const [RedetSFMessageID, setRedetSFMessageID] = useState("");
  const [IsDSNPMember, setIsDSNPMember] = useState("");
  const [DSNPdetails, setDSNPdetails] = useState([]);
  const [VA_Category, setVACategory] = useState("");
  const [IsOHIORISE, setIsOHIORISE] = useState(false);
  const [OH_CSPValue, setOHCSPValue] = useState("");

  const [stateFullName, setStateFullName] = useState("");
  const [city, setCity] = useState("");
  const [ServiceCoordinatorPhoneNumber, setServiceCoordinatorPhoneNumber] =
    useState([]);
  const [PDPName, setPDPName] = useState("");

  const [isCABrightcareMember, setIsCABrightcareMember] = useState("");

  //end meminfoall
  const unsecuredPageUrls = serverConstants.unsecuredPages;

  const setSupportUser = (data) => {
    setIsSupportUser(false || !!data?.SupportUserID || !!data?.SupportUserId); //This will be Support User Id (MMC id). If this is not null, UI to suppress functionalities which cause DML operations at backend.
    setSupportUserId(data?.SupportUserID ? data?.SupportUserID : "");
  };

  const setData = (data) => {
    // setRefreshSession(false);
    setMemberFirstName(data.MemApiDetails.FirstName);
    setMemberLastName(data.MemApiDetails.LastName);
    setId(data.MemApiDetails.MemberNumber);
    setUnreadInboxMsgCount(data.MemApiDetails.UnreadInboxMsgCount);
    setMemberStateCode(data.MemApiDetails.StateCode);
    setMemberLOB(data.MemApiDetails.LOB);
    setGraceStatus(data.GraceDetails?.GracePeriodStatus);
    setMemberRKSID(data.MemApiDetails.MemberRKSID);
    setProgramId(data.ProgramId);
    setRoleId(data.RoleID);
    setUserType(getUserType(data.RoleID));
    setShowAccordant(parseInt(data.ShowAccordant) == 1 ? true : false);
    setUserId(data.UserID);

    setZip(data.ZIP);
    setIsNYAffinityMember(
      data.IsNYAffinityMember?.trim() === "Y" ? true : false
    );
    setIsILHBIMember(data.IsILHBIMember?.trim() === "Y" ? true : false);
    setIsFIMCMember(data.MemApiDetails.IsFIMCMember);
    setIsDualEligible(data.IsDualEligible?.trim());
    setIsWISSIMember(data.IsWISSIMember?.trim() === "Y" ? true : false);
    setIsNYEssentialPlanUser(
      data.IsNYEssentialPlanUser?.trim() === "Y" ? true : false
    );
    setIsMCCMemberFlag(data.IsMCCMemberFlag?.trim() === "Y" ? true : false);
    setRedeterminationAlerts(data.RedeterminationAlerts);
    setIsDSNPMember(data.IsDSNPMember);
    //MI PDP implementation
    setPDPName(data.PDPName);
    setDSNPdetails(data.DSNPdetails);
    setVACategory(data.VA_Category);
    setServiceCoordinatorPhoneNumber(data?.MemberInformation?.CaseManagerPhone);
    setIsOHIORISE(
      data.IsOHIORISE?.trim().toLowerCase() === "true" ? true : false
    );
    setOHCSPValue(data.OH_CSPValue?.trim());
    setIsCABrightcareMember(
      data.IsCABrightCareMember?.trim() === "Y" ? true : false
    );
    setStateFullName(data.StateFullName);
    setServiceCoordinatorPhoneNumber(data?.MemberInformation?.CaseManagerPhone);

    //meminfoall
    setPrefPhone(data.MemberInformation.PreferredPhone);
    setHomePhone(data.MemberInformation.HomePhone);
    setCellPhone(data.MemberInformation.CellPhone);
    setGender(data.MemberInformation.Gender);
    setDateOfBirth(data.MemberInformation.DateOfBirth);
    setPlanName(data.MemberInformation.EnrollmentPlan);
    setCoverageEffDt(data.MemberInformation.EnrollmentEffDate);
    setPlanTerminationDt(data.PlanTerminationDate);
    setMailingAddress(data.MemberInformation.MailingAddress);
    setPrefEmailId(data.MemberInformation.Email);
    setPrefLang(data.MemberInformation.PreferredLanguage);
    setMemberId(data.MemberInformation.MemberId);
    setDoctorName(data.MemberProfile.PCPName);
    //MI PDP implementation
    setPDPName(data.PDPName);
    setMemberName(data.MemberInformation.memberName);
    setMemberStateName(data.MemApiDetails.MemberStateName);
    setPPHRAlerts(data.MemApiDetails?.NotificationAlerts?.PPHRAlerts);
    setPlanId(data.PlanId);
    setDependentCategory(data.MemberInformation.DependentCategory);
    setIsAIANMember(data.MemberInformation.IsAIANMember);
    setEnrollmentStatus(data.MemberInformation.EnrollmentStatus);
    setPrimaryCaseManager(data.MemberInformation.PrimaryCaseManager);
    setEnrollmentEffDate(data.MemberInformation.EnrollmentEffDate);
    setCity(data.MemberInformation.City);
    //end meminfoall
    // dependent details
    setDependentDetails(data?.DependentDetails);
    setIsDualEligible1(data?.IsDualEligible);
    setIsMemInfoResponseLoaded(true);
    const covDate =
      data.MemberInformation.EnrollmentEffDate &&
      new Date(data.MemberInformation.EnrollmentEffDate);
    const terminatedDate =
      data.PlanTerminationDate && new Date(data.PlanTerminationDate);

    const curDate = new Date();
    if (terminatedDate == "") {
      setIsTermedMember(false);
    } else if (covDate < curDate && terminatedDate < curDate) {
      setIsTermedMember(true);
    }

    //Redetermination Date from RedeterminationAlerts list
    if (
      data?.RedeterminationAlerts &&
      data?.RedeterminationAlerts?.length > 0
    ) {
      for (let alert of data.RedeterminationAlerts) {
        if (
          alert &&
          alert.RedeterminationDate &&
          alert.AlertType?.toUpperCase() == HEALTH_PLAN_RENEWALS
        ) {
          setRedeterminationDate(alert.RedeterminationDate);
          setRedetSFMessageID(alert.SFMessageID?.trim());
          break;
        }
      }
    }

    setSupportUser(data);
  };

  const createLoginSession = (data) => {
    setMemberFirstName(data.FirstName);
    setPrefLang(data.Language);
    setMemberLastName(data.LastName);
    setMemberLOB(data.MemberLob);
    setMemberStateCode(data.StateCode);
    setId(data.MemberNo);
    setDoctorName(data.PCPName);
    setPDPName(data.PDPName);
    setPlanName(data.PlanName);
    setPlanTerminationDt(data.PlanTerminationDate);
    setMemberRKSID(data.RKSMemberId);
    setRoleId(data.RoleId);
    setMemberStateName(data.StateName);
    setUserId(data.UserId);
    setUserType(getUserType(data.RoleId));
    setMemberName(data.LastName + "," + data.FirstName);
    setSupportUser(data);
    setPrimaryCaseManager(data.PrimaryCaseManager);
    setEnrollmentEffDate(data.EnrollmentEffDate);
  };

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("mc")) {
      localStorage.setItem("messageQuery", queryParams.get("mc"));
    }
    if (queryParams.get("redirectto")) {
      localStorage.setItem("landingPage", queryParams.get("redirectto"));
    }
  }, []);

  useEffect(async () => {
    setIsCABrightcareMember(
      typeof (await brightCarePortal()) == "boolean"
        ? await brightCarePortal()
        : false
    );
  }, []);

  useEffect(() => {
    const sessionData = document.getElementById("__SESSION_DATA__");
    if (refreshSession || refreshSessionAndReload) {
      callApi();
    } else if (
      !(
        parseRouteUrl(location.pathname).sitecoreRoute === undefined ||
        unsecuredPageUrls.indexOf(
          "/" + parseRouteUrl(location.pathname).sitecoreRoute
        ) >= 0
      )
    ) {
      if (!memberRKSID) {
        if (sessionData?.innerHTML) {
          const sessionObj = JSON.parse(sessionData.innerHTML);
          if (sessionObj?.ResultData?.MemberInformation) {
            setData(sessionObj.ResultData);
          } else if (sessionObj?.MemberNo) {
            createLoginSession(sessionObj);
            callApi();
          } else if (localStorage.getItem("loginData")) {
            createLoginSession(JSON.parse(localStorage.getItem("loginData")));
            localStorage.removeItem("loginData");
            callApi();
          } else {
            callApi();
          }
        } else {
          callApi();
        }
      }
    } else {
      if (sessionData?.innerHTML) {
        const sessionObj = JSON.parse(sessionData.innerHTML);
        if (sessionObj?.ResultData?.MemberInformation) {
          setData(sessionObj.ResultData);
        }
      }
    }
  }, [refreshSession, refreshSessionAndReload]);

  const callApi = () => {
    refreshSessionAndReload && setSpinner(true);
    MemberInfoAllApi.getMemberInfoAll({
      DeviceModel: "iphone6",
      DeviceOS: "IOS",
      AppVersion: "4.0.1",
      MemberNo: "",
      StateName: "",
    })
      .then((response) => {
        !refreshSessionAndReload && setData(response?.ResultData);
        if (refreshSession) {
          setRefreshSession(false);
          setIsMemberAPICalled(true);
        }
        if (refreshSessionAndReload) {
          setIsMemberAPICalled(true);
        }
      })
      .catch(function (err) {
        setError({ err });
        setRefreshSession(false);
      })
      .finally(function () {
        setSpinner(false);
      });
  };

  const refreshMemberInfoAll = () => {
    setRefreshSession(true);
  };

  const getBrightCarePortal = async () => {
    setIsCABrightcareMember(
      typeof (await brightCarePortal()) == "boolean"
        ? await brightCarePortal()
        : false
    );
  };

  const refreshMemberInfoAndReload = (url = redirectUrl) => {
    setRefreshSessionAndReload(true);
    setRedirectUrl(url);
  };

  useEffect(() => {
    if (refreshSessionAndReload && isMemberAPICalled) {
      setRefreshSessionAndReload(false);
      if (redirectUrl) {
        location.href = redirectUrl;
      } else {
        location.reload(false);
      }
    }
  }, [isMemberAPICalled]);

  // DSNP & MP LOB SWITCH - SUPPORT USER HANDLE
  useEffect(() => {
    const isSupportUser_LocalStorage = localStorage.getItem(
      "IsSupportUser_LocalStorage"
    );

    if (isSupportUser && IsDSNPMember && !isSupportUser_LocalStorage) {
      localStorage.setItem("IsSupportUser_LocalStorage", true);
    } else if (
      isSupportUser &&
      memberLOB?.toLowerCase() === MARKETPLACE &&
      !isSupportUser_LocalStorage
    ) {
      localStorage.setItem("IsSupportUser_LocalStorage", true);
    } else {
      // DO NOTHING
    }

    // UPDATING isSupportUser FOR DSNP & MARKETPLACE
    if (
      (IsDSNPMember || memberLOB?.toLowerCase() === MARKETPLACE) &&
      isSupportUser_LocalStorage &&
      !isSupportUser
    ) {
      setIsSupportUser(true);
    }
  }, [isSupportUser, IsDSNPMember, memberLOB]);

  return {
    userType,
    refreshMemberInfoAll,
    refreshMemberInfoAndReload,
    createLoginSession,
    getBrightCarePortal,
    isMemberAPICalled,
    unreadInboxMsgCount,
    memberFirstName,
    memberLastName,
    id,
    memberLOB,
    graceStatus,
    memberRKSID,
    programId,
    roleId,
    showAccordant,
    userId,
    gender,
    dateOfBirth,
    planName,
    coverageEffDt,
    planTerminationDt,
    mailingAddress,
    prefEmailId,
    prefPhone,
    prefLang,
    homePhone,
    cellPhone,
    memberId,
    doctorName,
    IsDualEligible,
    memberName,
    MemberStateName,
    PPHRAlerts,
    DependentCategory,
    DependentDetails,
    isAIANMember,
    isMemInfoResponseLoaded,
    refreshSession,
    planId,
    zip,
    isTermedMember,
    isSupportUser,
    supportUserId,
    EnrollmentStatus,
    isNYAffinityMember,
    isILHBIMember,
    isFIMCMember,
    isDualEligible,
    IsWISSIMember,
    isNYEssentialPlanUser,
    IsMCCMemberFlag,
    memberStateCode,
    primaryCaseManager,
    enrollmentEffDate,
    RedeterminationAlerts,
    RedeterminationDate,
    RedetSFMessageID,
    IsDSNPMember,
    DSNPdetails,
    VA_Category,
    IsOHIORISE,
    OH_CSPValue,
    city,
    stateFullName,
    isCABrightcareMember,
    ServiceCoordinatorPhoneNumber,
    PDPName,
  };
};

export { useMemberAPI };
